<template>
  <component
    :is="as"
    ref="sectionRef"
    v-bind="$attrs"
    class="clipped-component"
  >
    <slot />
    <clip
      v-if="clipTop"
      as="div"
      class="clipper before"
      :clip-top="clipTop"
      :clip-top-mobile="clipTopMobile"
      :style="cssVars({ prevBackground })"
    />
    <clip
      v-if="clipBottom"
      as="div"
      class="clipper after"
      :clip-top="clipBottom"
      :clip-top-mobile="clipBottomMobile"
      :style="cssVars({ nextBackground })"
    />
  </component>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { cssVars } from '@/utils/css';
import { useMutationObserver } from '@/composables/observer';

export default {
  props: {
    as: { type: String, default: 'section' },
    clipTop: { type: String },
    clipTopMobile: { type: String },
    clipBottom: { type: String },
    clipBottomMobile: { type: String },
  },
  setup(props) {
    const sectionRef = ref(null);
    const nextBackground = ref('white');
    const prevBackground = ref('white');
    const mainRef = ref(null);

    const calculateBackground = () => {
      const section = sectionRef.value;
      if (section?.nextSibling?.classList?.contains('part')) {
        const styles = window.getComputedStyle(section.nextSibling);
        if (
          styles.backgroundColor.includes('rgba') && styles.backgroundColor.endsWith(', 0);')
        ) {
          return;
        }
        nextBackground.value = styles.backgroundColor;
      }
      if (section?.previousSibling?.classList?.contains('part')) {
        const styles = window.getComputedStyle(section.previousSibling);
        if (
          styles.backgroundColor.includes('rgba') && styles.backgroundColor.endsWith(', 0)')
        ) {
          return;
        }
        prevBackground.value = styles.backgroundColor;
      }
    };

    const { observe, unobserve } = useMutationObserver(
      mainRef,
      calculateBackground,
      { childList: true },
    );

    onMounted(() => {
      mainRef.value = sectionRef.value?.closest('main');
      calculateBackground();
      observe();
    });

    onUnmounted(() => {
      unobserve();
    });

    return {
      sectionRef,
      nextBackground,
      prevBackground,
      cssVars,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.clipper {
  @apply bg-white;

  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &.before {
    background: var(--prev-background, theme('colors.white'));
  }

  &.after {
    background: var(--next-background, theme('colors.white'));
  }
}
</style>
