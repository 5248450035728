<template>
  <clip-sides
    ref="sectionRef"
    as="section"
    class="bild"
    clip-top="ellipse(160% 100% at 40% -90%)"
    clip-bottom="ellipse(120% 50% at 90% 120%)"
    clip-bottom-mobile="ellipse(120% 50% at 90% 130%)"
  >
    <slider
      ref="sliderRef"
      v-slot="{ item }"
      class="slider"
      :items="payload.bilder"
      :slides-per-view="{ desktop: 1, mobile: 1 }"
      :slides-per-group="{ desktop: 1, mobile: 1 }"
      :space-between="{ desktop: 0, mobile: 0 }"
      @progress="onProgress"
    >
      <Image v-view class="animate in-bottom slider-image" :src="item" />
    </slider>
    <div class="swiper-nav">
      <div class="constrain">
        <div class="row-12">
          <div class="col-12 md:offset-1">
            <span class="arrow swiper-button-prev" :class="{'opacity-50': state == 'start'}" @click="prev()">
              <arrow class="arrow-right transform rotate-180 mr-8" />
            </span>
            <span class="arrow swiper-button-next" :class="{'opacity-50': state == 'end'}" @click="next()">
              <arrow class="arrow-right" />
            </span>
          </div>
        </div>
      </div>
    </div>

  </clip-sides>
</template>

<script>
import { ref } from 'vue';
import { cssVars } from '@/utils/css';
import { useSwiperBounds } from '@/composables/swiper';
import ClipSides from '@/components/utils/ClipSides.vue';
import Arrow from '@/assets/images/arrow-right.svg';

export default {
  components: { ClipSides, Arrow },
  props: { payload: { type: Object, default: () => ({}) } },
  setup() {
    const sliderRef = ref(null);

    const { onProgress, state } = useSwiperBounds();
    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    return {
      cssVars,
      Arrow,
      prev,
      next,
      onProgress,
      sliderRef,
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.slider {
  overflow: visible;
  height: 100vh;
}

.slider-image {
  @apply object-cover;
}

.bild {
  position: relative;
  z-index: 10;
  @screen md {
    margin-top: px(-100);

    & + :deep(.part) > * {
      @screen md {
        margin-top: px(-100);
      }
      @screen lg {
        margin-top: px(-150);
      }
      @screen xl {
        margin-top: px(-200);
      }
    }
  }

  & + :deep(*) {
    position: relative;
    z-index: 10;
  }
}

.swiper-nav {
  @apply absolute z-10;
  width: 100%;
  top: 65%;
}

.arrow-right {
  @include responsive('width', 1.5rem, 2.5rem);
}

.arrow {
  @apply inline-block cursor-pointer;
  :deep {
    svg {
      display: inline;
    }
  }
}
</style>
